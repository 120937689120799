export const TOKEN_KEY = "apitoken_palma_finanes";
export const USERDATA = "userdata_palma_finanes";

export const isAuthenticated = () => {
  // const token = localStorage.getItem(TOKEN_KEY);
  // return token !== null;
  const token = getCookie('PalmaFinanceToken');
  return token !== "";
};

export const getToken = () => {
  const token = getCookie('PalmaFinanceToken');
  console.debug("getToken",token);
  return token;
};

export const getUser = () => {
  const user = getCookie('PalmaFinanceUser');
  if (user !== "") {
    return JSON.parse(user); 
  } else {
    return null;
  }
};

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const saveUser = (user) => {
  const userFormatted = JSON.stringify(user);
  localStorage.setItem(USERDATA, userFormatted);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USERDATA);
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}